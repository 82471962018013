import React, { Component, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './NavMenu.css'

const AdminNavMenu = () => {
  const [collapsed, setCollapsed] = useState(true)
  const location = useLocation()

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  return (
    <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s" >
      <a href="/admin?page=1" class="navbar-brand ms-4 ms-lg-0">
        <h1 class="text-primary m-0"><img class='img-center-crop' src="./favicon.png" alt="Icon" /><span>maytrethaibinh</span></h1>
      </a>
      <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class=" collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto p-4 p-lg-0">
          <a href="/admin?page=1" className={`nav-item nav-link ${location.pathname === '/admin' ? 'active' : ''}`}>Products</a>          
          <a href="/admin-categories" className={`nav-item nav-link ${location.pathname === '/admin-categories' ? 'active' : ''}`}>Categories</a>          
        </div>
        <a href="/logout" class="btn btn-primary py-2 px-4 d-none d-lg-block">Logout</a>
      </div>
    </nav>
  );
}

export default AdminNavMenu;
