import React, { Component, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './NavMenu.css'

const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true)
  const location = useLocation()

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  return (
    <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s" >
      <a href="/" class="navbar-brand ms-4 ms-lg-0">
        <h1 class="text-primary m-0"><img class='img-center-crop' src="./favicon.png" alt="Icon" /><span>maytrethaibinh</span></h1>
      </a>
      <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class=" collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav ms-auto p-4 p-lg-0">
          <a href="/" className={`nav-item nav-link ${location.pathname === '/' ? 'active' : ''}`}>Home</a>
          <a href="/about" className={`nav-item nav-link ${location.pathname === '/about' ? 'active' : ''}`}>About</a>
          <a href="/products?page=1" className={`nav-item nav-link ${location.pathname === '/products' ? 'active' : ''}`}>Products</a>
          <a href="/services" className={`nav-item nav-link ${location.pathname === '/services' ? 'active' : ''}`}>Services</a>
          <a href="/contact" className={`nav-item nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>Contact</a>
        </div>
        <a href="/appointment" class="btn btn-primary py-2 px-4 d-none d-lg-block">Appointment</a>
      </div>
    </nav>
  );
}

export default NavMenu;
