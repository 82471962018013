import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '../d_api/api';
import { imageReviewContainerStyle, imageReviewIconRemoveStyle, imageReviewStyle } from '../e_services/Constants';

const ImagePreview = (props) => {
  const { images, callback } = props

  const handleRemove = (index) => {
    console.log(index)
    callback && callback(index)
  }

  return (
    images && images.map((x, index) => {
      return <div style={imageReviewContainerStyle}>
        <img style={imageReviewStyle} src={`${x.src}`} />
        <i onClick={() => { handleRemove(index) }} style={imageReviewIconRemoveStyle} class="fa fa-times" aria-hidden="true"></i>
      </div>
    })
  );
}

export default ImagePreview;