export const Loading = () => {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          {/* <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Loading</h4>
          </div> */}
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}