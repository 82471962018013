import React, { Component, useEffect } from 'react';
import { defaultServices } from '../e_services/Constants';

const WidgetServices2 = () => {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Our Services</h4>
            <h1 class="display-5 mb-4">We Focused On Modern Architecture And Interior Design</h1>
          </div>
          <div class="row g-4">
            {defaultServices.map((k, v) => {
              return <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="service-item d-flex position-relative text-center h-100">
                  <img class="bg-img" src={`${k.img1}`} alt="" />
                  <div class="service-text p-5">
                    <img class="mb-4" src={`${k.img2}`} alt="" />
                    <h3 class="mb-3">{k.name}</h3>
                    <p class="mb-4">{k.desc}</p>
                    <a class="btn" href=""><i class="fa fa-plus text-primary me-3"></i>Read More</a>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetServices2;