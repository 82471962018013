import React, { Component, useCallback, useEffect, useState } from 'react';
import ProductItem from '../c_components/ProductItem';
import { useParams } from 'react-router-dom';
import api from '../d_api/api';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { Loading } from '../c_components/Loading';
import { Constants, productDefaultProperties, productProperties } from '../e_services/Constants';
import { Layout } from '../a_layout/Layout';

const Product = () => {
    const { id } = useParams()
    const [product, setProduct] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const getProduct = useCallback(async () => {
        try {
            const { data } = await api.get(`/api/products/${id}`)
            setProduct(data)
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }, [id])

    useEffect(() => {
        getProduct()
    }, [getProduct])

    const renderBody = () => {
        let body = []
        const relatedProducts = product.relatedProducts

        if (!relatedProducts) return

        for (let i = 0; i < relatedProducts.length; i += 4) {
            body.push(
                <div class="row g-4">
                    <ProductItem item={relatedProducts[i + 0]} />
                    <ProductItem item={relatedProducts[i + 1]} />
                    <ProductItem item={relatedProducts[i + 2]} />
                    <ProductItem item={relatedProducts[i + 3]} />
                </div>)
        }

        return body
    }

    return (
        isLoading ? <Loading />
            : product && <>
                <div class="container-xxl py-5">
                    <div class="container">
                        <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
                            <h4 class="section-title">{product.categoryName} // {product.name}</h4>
                        </div>
                    </div>
                </div>
                <div class="container-xxl py-5">
                    <div class="container">
                        <div class="row g-5">
                            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                <Carousel images={product.images || []} style={{ "width": "100%" }} canAutoPlay={false} />
                            </div>
                            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <h1 class="display-5 mb-4">{product.name}</h1>
                                <p><strong>Ratings: </strong><span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star checked"></span>
                                    <span class="fa fa-star"></span></p>
                                <p>{product.description}</p>
                                <p>We are a manufacturing company in rattan materials, rattan decorative products and rattan furniture. We are suppling the variety of high-quality rattan product and providing a number of services in rattan weaving, Danish paper cord weaving, Linen webbing weaving, leather webbing weaving.</p>
                                <p class="mb-4">We were established in 2019 to balance the huge demand from customer for rattan product and weaving services. We are committed to bring our high-quality rattan material, rattan decorative products and rattan furniture to the world in the sustainable and environmental ways.</p>
                                <button class="btn btn-primary w-80 py-2 btn-sm">Contact Us: {Constants.Phone}</button>
                            </div>
                        </div>

                    </div>
                    <div class='container' style={{ "marginTop": "20px" }}>
                        <div class="row">
                            <nav>
                                <div class="nav nav-tabs product-general-details" id="nav-tab" role="tablist">
                                    <button class="nav-link" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Description</button>
                                    <button class="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Specifications</button>
                                    <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Delivery & Returns</button>
                                </div>
                            </nav>
                            <div class="product-tabs tab-content" id="nav-tabContent">
                                <div class="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <p><strong>{product.name}</strong></p>
                                    <p>{product.description}</p>
                                    <p><strong>Dimensions: </strong>{(product.properties && product.properties[0]) || productDefaultProperties[0]}</p>
                                    <p>SKU: {(product.properties && product.properties[7]) || productDefaultProperties[7]}</p>
                                </div>
                                <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <div class="container">
                                        <div class="row row-cols-4">
                                            {productProperties.map((k, v) => {
                                                const oddcolor = [0, 1, 4, 5].includes(v) ? 'product-specs--oddcolor' : ''
                                                const productProperties = product.properties || productDefaultProperties
                                                return <>
                                                    <div class={`col ${oddcolor} product-specs--name`}>{k}:</div>
                                                    <div class={`col ${oddcolor} product-specs--value`}>{productProperties[v] || productDefaultProperties[v]}</div>
                                                </>
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-xxl py-5">
                    <div class="container">
                        <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
                            <h4 class="section-title">Related products</h4>
                        </div>
                        {
                            renderBody()
                        }
                    </div>
                </div>
            </>
    );
}

export default Layout(Product)