import React, { Component, useEffect } from 'react';

const WidgetSlider = () => {
  return (
    <>
      <div class="container-fluid p-0 pb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="owl-carousel header-carousel position-relative">
          <div class="owl-carousel-item position-relative" data-dot="<img src='/img/carousel-1.jpg'>">
            <img class="img-fluid" src="/img/carousel-1.jpg" alt="" />
            <div class="owl-carousel-inner">
              <div class="container">
                <div class="row justify-content-start">
                  <div class="col-10 col-lg-8">
                    <h1 class="display-1 text-white animated slideInDown">High quality rattan material</h1>
                    <p class="fs-5 fw-medium text-white mb-4 pb-3">Our factories are located nearby rattan and bamboo forest. Hence, we can choose the best quality material for production..</p>
                    <a href="" class="btn btn-primary py-3 px-5 animated slideInLeft">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="owl-carousel-item position-relative" data-dot="<img src='img/carousel-2.jpg'>">
            <img class="img-fluid" src="img/carousel-2.jpg" alt="" />
            <div class="owl-carousel-inner">
              <div class="container">
                <div class="row justify-content-start">
                  <div class="col-10 col-lg-8">
                    <h1 class="display-1 text-white animated slideInDown">Special rattan Weaving</h1>
                    <p class="fs-5 fw-medium text-white mb-4 pb-3">Furthermore, our craftsman and craftswoman have many experienced years manufacturing and processing rattan, bamboo therefore we can produce many products accordingly to customers' design.</p>
                    <a href="" class="btn btn-primary py-3 px-5 animated slideInLeft">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="owl-carousel-item position-relative" data-dot="<img src='img/carousel-3.jpg'>">
            <img class="img-fluid" src="img/carousel-3.jpg" alt="" />
            <div class="owl-carousel-inner">
              <div class="container">
                <div class="row justify-content-start">
                  <div class="col-10 col-lg-8">
                    <h1 class="display-1 text-white animated slideInDown">Rattan decoration</h1>
                    <p class="fs-5 fw-medium text-white mb-4 pb-3">Thicker, heavier and sometimes stronger than flat weave. This is a more premium weave that carefully imitates real rattan.</p>
                    <a href="" class="btn btn-primary py-3 px-5 animated slideInLeft">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetSlider;