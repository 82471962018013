import React, { useEffect, useState } from 'react';
import { AdminLayout } from '../../a_layout/AdminLayout';
import { ProtectedPage } from './ProtectedPage';
import api from '../../d_api/api';
import { Loading } from '../../c_components/Loading';
import AdminCategoryCreate from './AdminCategoryCreate';
import AdminCategoryDelete from './AdminCategoryDelete';
import AdminCategoryUpdate from './AdminCategoryUpdate';

const AdminCategories = () => {
  const [isLoading, setIsLoading] = useState(false)

  const [categories, setCategories] = useState([])
  const [shouldRender, setShouldRender] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  const [showCreateNew, setShowCreateNew] = useState(false);
  const handleCloseCreate = () => setShowCreateNew(false);
  const handleShowCreate = () => setShowCreateNew(true);

  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = () => setShowUpdate(true);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const getData = async () => {
    setIsLoading(true)

    try {
      const catResponse = await api.get("/api/categories")
      setCategories(catResponse.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  // update
  const openUpdateForm = (item) => {
    setSelectedItem(item)
    handleShowUpdate()
  }

  const afterUpdateCallback = (item) => {
    const prod = categories.find(x => x.id === item.id)    
    prod.name = item.name    
    setShouldRender(!shouldRender)
  }

  // delete
  const openDeleteForm = (item, index) => {
    setSelectedItem(item)
    handleShowDelete()
  }

  const afterDeleteCallback = () => {
    const index = categories.findIndex(x => x.id === selectedItem.id)
    if (index > -1) {
      categories.splice(index, 1)
      setShouldRender(!shouldRender)
    }
  }

  // create
  const openCreateForm = () => {
    handleShowCreate()
  }

  const afterCreateCallback = (item) => {
    categories.push(item)
    setShouldRender(!shouldRender)
  }

  const renderBody = () => {
    return categories?.map((item, key) => {
      return <tr>
        <td class='col-md-1'>{key+1}</td>
        <td class='col-md-5'>{item.name}</td>
        <td class='col-md-1'></td>
        <td class='col-md-3'>
          <button class="btn btn-primary w-70 py-2" style={{ "marginRight": "5px" }} onClick={() => openUpdateForm(item)}>Update</button>
          <button class="btn btn-primary w-70 py-2" onClick={() => openDeleteForm(item, key)}>Delete</button>
        </td>
      </tr>
    })
  }

  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Admin // Manage your categories</h4>
          </div>
          <table class="table" align='center' style={{ "maxWidth": "800px" }}>
            <thead>
              {!isLoading && <tr>
                <th scope="col-md-1">#</th>
                <th scope="col-md-5">Name</th>
                <th scope="col-md-1"></th>
                <th scope="col-md-3">
                  <button class="btn btn-primary w-70 py-2" onClick={() => openCreateForm()}>Create New</button>
                </th>
              </tr>}
            </thead>
            <tbody>
              {isLoading ? <tr><td colSpan={5}><Loading /></td></tr> : renderBody()}
            </tbody>
          </table>
        </div>
      </div>
      {showCreateNew && <AdminCategoryCreate show={showCreateNew}
        handleClose={handleCloseCreate}
        categories={categories}
        callback={afterCreateCallback}
      />}
      {showUpdate && <AdminCategoryUpdate show={showUpdate}
        handleClose={handleCloseUpdate}
        item={selectedItem}
        categories={categories}
        callback={afterUpdateCallback}
      />
      }
      {showDelete && <AdminCategoryDelete show={showDelete}
        handleClose={handleCloseDelete}
        item={selectedItem}
        callback={afterDeleteCallback}
      />
      }
    </>
  );
}

export default ProtectedPage(AdminLayout(AdminCategories));
