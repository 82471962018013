import axios from 'axios';

const api = axios.create();

// Request interceptor for adding the bearer token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('ABC'); // Assuming you store the token in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response
  },
  err => {
    if (err.response.status === 401 || err.response.status === 403) {
      window.location.href = '/login';
    }    
    const message = err.response?.data.message || err.message
    return Promise.reject(message);
  })

// Export the api instance
export default api;
