import About from './b_pages/About';
import Appointment from './b_pages/Appointment';
import Contact from './b_pages/Contact';
import FourZeroFour from './b_pages/FourZeroFour';
import Product from './b_pages/Product';
import Services from './b_pages/Services';
import Home from "./b_pages/Home";
import Products from './b_pages/Products';
import Login from './b_pages/admin/Login';
import Logout from './b_pages/admin/Logout';
import AdminCategories from './b_pages/admin/AdminCategories';
import AdminProducts from './b_pages/admin/AdminProducts';
import Term from './b_pages/Term';
import Privacy from './b_pages/Privacy';

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/products',
    element: <Products />
  },
  {
    path: '/products/:id',
    element: <Product />
  },
  {
    path: '/services',
    element: <Services />
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/appointment',
    element: <Appointment />
  },
  {
    path: '/*',
    element: <FourZeroFour />
  },
  {
    path: '/admin',
    element: <AdminProducts />
  },
  {
    path: '/admin-categories',
    element: <AdminCategories />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/logout',
    element: <Logout />
  },
  {
    path: '/terms',
    element: <Term />
  },
  {
    path: '/privacy',
    element: <Privacy />
  }
];

export default AppRoutes;
