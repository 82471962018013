import React, { useCallback, useEffect, useState } from 'react';
import { AdminLayout } from '../../a_layout/AdminLayout';
import { ProtectedPage } from './ProtectedPage';
import api from '../../d_api/api';
import { Loading } from '../../c_components/Loading';
import AdminProductUpdate from './AdminProductUpdate';
import AdminProductCreate from './AdminProductCreate';
import AdminProductDelete from './AdminProductDelete';
import { useQueryParams } from '../../f_hooks/useQueryParams';
import { useNavigate } from 'react-router-dom';

const AdminProducts = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [shouldRender, setShouldRender] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  const [showCreateNew, setShowCreateNew] = useState(false);
  const handleCloseCreate = () => setShowCreateNew(false);
  const handleShowCreate = () => setShowCreateNew(true);

  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  const handleShowUpdate = () => setShowUpdate(true);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const { page } = useQueryParams()

  const getData = async () => {
    setIsLoading(true)

    try {
      const prodRequest = api.get(`/api/products?page=${page || 1}`)
      const catRequest = api.get("/api/categories")

      const [prodResponse, catResponse] = await Promise.all([prodRequest, catRequest])

      const data = prodResponse.data
      data.categories = catResponse.data

      setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [page])

  // update
  const openUpdateForm = (item) => {
    setSelectedItem(item)
    handleShowUpdate()
  }

  const afterUpdateCallback = (item) => {
    const prod = data.products.find(x => x.id === item.id)
    if (prod) {
      prod.name = item.name
      prod.description = item.description
      prod.images = item.images
      prod.categoryId = item.categoryId
      prod.properties = item.properties
      setShouldRender(!shouldRender)
    }
  }

  // delete
  const openDeleteForm = (item, index) => {
    setSelectedItem(item)
    handleShowDelete()
  }

  const afterDeleteCallback = () => {
    const index = data.products.findIndex(x => x.id === selectedItem.id)
    if (index > -1) {
      data.products.splice(index, 1)
      setShouldRender(!shouldRender)
    }
  }

  // create
  const openCreateForm = () => {
    handleShowCreate()
  }

  const afterCreateCallback = (item) => {
    data.products.push(item)
    setShouldRender(!shouldRender)
  }

  const renderBody = () => {
    return data.products?.map((item, key) => {
      return <tr>
        <td class='col-md-1'>{key+1}</td>
        <td class='col-md-2'>{item.name}</td>
        <td class='col-md-5'>{item.description}</td>
        <td class='col-md-2'>@image</td>
        <td class='col-md-3'>
          <button class="btn btn-primary w-70 py-2" style={{ "marginRight": "5px" }} onClick={() => openUpdateForm(item)}>Update</button>
          <button class="btn btn-primary w-70 py-2" onClick={() => openDeleteForm(item, key)}>Delete</button>
        </td>
      </tr>
    })
  }

  const navigate = useNavigate()
    const onChangePage = (i) => {
        navigate({
            pathname: '/admin',
            search: `?page=${i}`,
        });
    }

    const renderPage = () => {
        if (!data?.pages) return

        let pages = []
        for (let i = 0; i < data.pages; i++) {
            pages.push(<button onClick={() => { onChangePage(i + 1) }} class={`btn ${i === page-1 ? 'btn-secondary' : 'btn-primary'}`} style={{ marginRight: '5px' }}>{i + 1}</button>)
        }

        return pages
    }

  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Admin // Manage your products</h4>
          </div>
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            {renderPage()}
          </div>
          <table class="table" align='center' style={{ "maxWidth": "1200px" }}>
            <thead>
              {!isLoading && <tr>
                <th scope="col-md-1">#</th>
                <th scope="col-md-2">Name</th>
                <th scope="col-md-5">Description</th>
                <th scope="col-md-2">Images</th>
                <th scope="col-md-3">
                  <button class="btn btn-primary w-70 py-2" onClick={() => openCreateForm()}>Create New</button>
                </th>
              </tr>}
            </thead>
            <tbody>
              {isLoading ? <tr><td colSpan={5}><Loading /></td></tr> : renderBody()}
            </tbody>
          </table>
        </div>
      </div>
      {showCreateNew && <AdminProductCreate show={showCreateNew}
        handleClose={handleCloseCreate}
        categories={data.categories}
        callback={afterCreateCallback}
      />}
      {showUpdate && <AdminProductUpdate show={showUpdate}
        handleClose={handleCloseUpdate}
        item={selectedItem}
        categories={data.categories}
        callback={afterUpdateCallback}
      />
      }
      {showDelete && <AdminProductDelete show={showDelete}
        handleClose={handleCloseDelete}
        item={selectedItem}
        callback={afterDeleteCallback}
      />
      }
    </>
  );
}

export default ProtectedPage(AdminLayout(AdminProducts));
