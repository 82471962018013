import React, { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Loading } from '../../c_components/Loading';
import api from '../../d_api/api';
import { useAuth } from '../../f_hooks/useAuth';

export const ProtectedPage = (WrappedComponent) => {
  const MyPrivPage = () => {
    const auth = useAuth()
    const [isLoading, setIsLoading] = useState(true)

    const handleAutoSignIn = async () => {
      try {
        await auth.autoSignIn()
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      handleAutoSignIn()
    }, [])

    return (
      isLoading ? <></> :
        auth.isAuthenticated ?
            <WrappedComponent /> : <Navigate to="/login?returnURL='/admin'" />
    )
  }

  return MyPrivPage
}