import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';

const ToastMessage = (props) => {
  const { error } = props
  const [show, setShow] = useState(true);
  const toggleShow = () => setShow(!show);

  useEffect(() => {
  }, [])

  return (
    <ToastContainer
      className="p-3"
      position={`top-center`}
      style={{ zIndex: 10000, top: '3%', position: 'fixed' }}
    >
      <Toast show={show} onClose={toggleShow}
        style={{ border: 'solid 1px red', color: 'red' }}
      >
        <Toast.Header closeButton={true} >
          <img
            src="favicon.png"
            className="rounded me-2"
            alt=""
            style={{ "width": "30px" }}
          />
          <strong className="me-auto">Error Information</strong>
          {/* <small>11 mins ago</small> */}
        </Toast.Header>
        <Toast.Body>{error}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default ToastMessage;