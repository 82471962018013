import React, { Component, useEffect } from 'react';

const WidgetAboutUs2 = () => {
  return (
    <>      
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h4 class="section-title">Why Choose Us!</h4>
              <h1 class="display-5 mb-4">Why You Should Trust Us? Learn More About Us!</h1>
              <p class="mb-4">From the beginning we’ve worked alongside many of the most important media and publishing companies. The results achieved and shared experiences are our greatest satisfaction, combined with the trust and lasting relationships built over time.</p>
              <div class="row g-4">
                <div class="col-12">
                  <div class="d-flex align-items-start">
                    <img class="flex-shrink-0" src="img/icons/icon-2.png" alt="" />
                    <div class="ms-4">
                      <h3>High quality</h3>
                      <p class="mb-0">A single, thin layer of material. It's strong and durable, making it great value for money.</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex align-items-start">
                    <img class="flex-shrink-0" src="img/icons/icon-3.png" alt="" />
                    <div class="ms-4">
                      <h3>Special rattan Weaving</h3>
                      <p class="mb-0">A beautiful, strong effect that is more three-dimensional in shape with a high-end feel to it.</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex align-items-start">
                    <img class="flex-shrink-0" src="img/icons/icon-4.png" alt="" />
                    <div class="ms-4">
                      <h3>Rattan Furniture</h3>
                      <p class="mb-0">Rattan furniture can be made from natural or synthetic weaves. When you’re exploring buying outdoor rattan furniture you’ll find it’s synthetic, not natural. And there’s a good reason for this.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="feature-img">
                <img class="img-fluid" src="img/about-2.jpg" alt="" />
                <img class="img-fluid" src="img/about-1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>      
    </>
  );
}

export default WidgetAboutUs2;