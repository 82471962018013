import React, { Component } from 'react';
import { Constants } from '../e_services/Constants';
import { Layout } from '../a_layout/Layout';

const Privacy = () => {
  return (
    <>
      <div class="container-fluid page-header-4 py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div class="container py-5">
          <h1 class="display-1 text-white animated slideInDown">Privacy</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb text-uppercase mb-0">
              <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
              <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
              <li class="breadcrumb-item text-primary active" aria-current="page">Privacy</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Privacy Policy</h4>
          </div>
          <div class="row g-5">
            <div class="text-left wow fadeInUp" data-wow-delay="0.5s">
              <p class="mb-4">Your privacy is important to us. It is {Constants.Site}'s policy to respect your privacy regarding any information we may collect from you across our website, [{Constants.Site}], and other sites we own and operate.</p>
              <p class="mb-4"><strong>Information we collect</strong></p>
              <p class="mb-4">We only collect information about you if we have a reason to do so — for example, to provide our services, to communicate with you, or to make our services better.</p>

              <p class="mb-4">We collect information in three ways: if and when you provide information to us, automatically through operating our services, and from outside sources. Let’s go over the information that we collect.</p>

              <p class="mb-4">Information You Provide to Us: We collect information about you when you input it into the Services or otherwise provide it directly to us.</p>

              <p class="mb-4">Information We Collect Automatically: We automatically collect certain information about you and the device with which you access the Website. For example, when you use the Website, we will log your IP address, operating system type, browser type, referring website, pages you viewed, and the dates/times when you accessed the Website.</p>

              <p class="mb-4"><strong>How we use your information</strong></p>
              <p class="mb-4">We use the information we collect in various ways, including to:</p>
              <i class="fa fa-check"></i> Provide, operate, and maintain our website<br/>
              <i class="fa fa-check"></i> Improve, personalize, and expand our website<br/>
              <i class="fa fa-check"></i> Understand and analyze how you use our website<br/>
              <i class="fa fa-check"></i> Develop new products, services, features, and functionality<br/>
              <i class="fa fa-check"></i> Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes<br/>              
              <i class="fa fa-check"></i> Send you emails<br/>
              <br/>
              <p><strong>Security</strong></p>
              <p class="mb-4">We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

              <p class="mb-4"><strong>Links to Other Sites</strong></p>
              <p class="mb-4">Our website may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

              <p class="mb-4"><strong>Changes to This Privacy Policy</strong></p>
              <p class="mb-4">We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

              <p class="mb-4"><strong>Contact Us</strong></p>
              If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout(Privacy);