import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import ToastMessage from '../../c_components/ToastMessage';
import api from '../../d_api/api';
import ShowSpinnerText from '../../c_components/SpinnerButton';

const AdminCategoryDelete = (props) => {
  const { show, handleClose, callback, item } = props
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleDeleteConfirm = async () => {
    setIsLoading(true)
    setError('')

    try {
      await api.delete(`/api/categories/${item.id}`)
      callback && callback()
      handleClose()
    } catch (error) {
      console.log(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are your sure you want to delete this item <strong>[{item.name}]</strong>?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDeleteConfirm}>
            <ShowSpinnerText text='Delete' isLoading={isLoading} />
          </Button>
        </Modal.Footer>
      </Modal>
      {error && <ToastMessage show={true} error={error} />}
    </>
  );
}

export default AdminCategoryDelete;
