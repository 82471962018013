import React, { useCallback, useEffect, useState } from 'react';
import api from '../../d_api/api';
import { Loading } from '../../c_components/Loading';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../f_hooks/useAuth';

const Logout = () => {
  const auth = useAuth()
  const [isLoading, setIsLoading] = useState(true)

  const handleLogout = async () => {
    try {
      await auth.logout()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  
  useEffect(() => {
    handleLogout()
  }, [])

  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Logout of Our System</h4>
            {isLoading ? <Loading /> : <Navigate to='/'></Navigate>}
          </div>
        </div>
      </div>
    </>
  );
}

export default Logout;
