import React from 'react';
import ToolbarStart from './ToolbarStart';
import Footer from './Footer';
import AdminNavMenu from './AdminNavMenu';

export const AdminLayout = (WrappedComponent) => {
  const MyLayout = () => {
    return (
      <>
        <ToolbarStart />
        <AdminNavMenu />
        <WrappedComponent />
        <Footer />
      </>)
  }

  return MyLayout
}
