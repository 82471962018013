import React, { Component, useEffect } from 'react';

const WidgetAboutUs1 = () => {
  const exYear = new Date().getUTCFullYear() - 2019

  return (
    <>      
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div class="about-img">
                <img class="img-fluid" src="img/about-1.jpg" alt="" />
                <img class="img-fluid" src="img/about-2.jpg" alt="" />
              </div>
            </div>
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h4 class="section-title">About Us</h4>
              <h1 class="display-5 mb-4">A Creative Rattan Furniture For Your Dream Home</h1>
              <p>We are a manufacturing company in rattan materials, rattan decorative products and rattan furniture. We are suppling the variety of high-quality rattan product and providing a number of services in rattan weaving, Danish paper cord weaving, Linen webbing weaving, leather webbing weaving.</p>
              <p class="mb-4">We were established in 2019 to balance the huge demand from customer for rattan product and weaving services. We are committed to bring our high-quality rattan material, rattan decorative products and rattan furniture to the world in the sustainable and environmental ways.</p>
              <p class="mb-4">In the situation of the shortage in rattan material that cause price increasing, we are come up with the best solution to build new product design, new production innovation to harmonize the demand and supply for our valued customer.</p>
              <p class="mb-4">We are looking for good cooperation, long-term business and new development from our valued customers. We would like have a contribution to your business growth and development in the near future.</p>
              <div class="d-flex align-items-center mb-5">
                <div class="d-flex flex-shrink-0 align-items-center justify-content-center border border-5 border-primary" style={{ "width": "120px", "height": "120px" }}>
                  <h1 class="display-1 mb-n2" data-toggle="counter-up">{exYear}</h1>
                </div>
                <div class="ps-4">
                  <h3>Years</h3>
                  <h3>Working</h3>
                  <h3 class="mb-0">Experience</h3>
                </div>
              </div>
              <a class="btn btn-primary py-3 px-5" href="">Read More</a>
            </div>
          </div>
        </div>
      </div>      
    </>
  );
}

export default WidgetAboutUs1;