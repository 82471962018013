import React, { Component } from 'react';
import WidgetAppointment from '../c_components/WidgetAppointment';
import { Layout } from '../a_layout/Layout';

class Appointment extends Component {
  static displayName = Appointment.name;  
  render() {
    return (
      <>
        <div class="container-fluid page-header-5 py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
          <div class="container py-5">
            <h1 class="display-1 text-white animated slideInDown">Appointment</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol class="breadcrumb text-uppercase mb-0">
                <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                <li class="breadcrumb-item text-primary active" aria-current="page">Appointment</li>
              </ol>
            </nav>
          </div>
        </div>
        <WidgetAppointment />
      </>
    );
  }
}

export default Layout(Appointment);
