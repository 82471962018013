import React, { Component, useEffect } from 'react';

const WidgetTeam = () => {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Team Members</h4>
            <h1 class="display-5 mb-4">We Are Creative Rattan Furniture Team For Your Dream Home</h1>
          </div>
          <div class="row g-0 team-items">
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="team-item position-relative">
                <div class="position-relative">
                  <img class="img-fluid" src="img/team-2.jpg" alt="" />
                  <div class="team-social text-center">
                    <a class="btn btn-square" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div class="bg-light text-center p-4">
                  <h3 class="mt-2">Phong Hoang</h3>
                  <span class="text-primary">CEO & Founder</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="team-item position-relative">
                <div class="position-relative">
                  <img class="img-fluid" src="img/team-1.jpg" alt="" />
                  <div class="team-social text-center">
                    <a class="btn btn-square" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div class="bg-light text-center p-4">
                  <h3 class="mt-2">Duong Pham</h3>
                  <span class="text-primary">Chief Accountant</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="team-item position-relative">
                <div class="position-relative">
                  <img class="img-fluid" src="img/team-3.jpg" alt="" />
                  <div class="team-social text-center">
                    <a class="btn btn-square" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div class="bg-light text-center p-4">
                  <h3 class="mt-2">Vung Tran</h3>
                  <span class="text-primary">Business</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="team-item position-relative">
                <div class="position-relative">
                  <img class="img-fluid" src="img/team-4.jpg" alt="" />
                  <div class="team-social text-center">
                    <a class="btn btn-square" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-square" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-square" href=""><i class="fab fa-instagram"></i></a>
                  </div>
                </div>
                <div class="bg-light text-center p-4">
                  <h3 class="mt-2">Hai Tran</h3>
                  <span class="text-primary">Designation</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetTeam;