import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import api from '../../d_api/api';
import ShowSpinnerText from '../../c_components/SpinnerButton';
import ToastMessage from '../../c_components/ToastMessage';

const AdminCategoryUpdate = (props) => {
  const { show, handleClose, callback, item } = props
  
  const [name, setName] = useState(item.name)
  const [desc, setDesc] = useState(item.description)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleUpdateProduct = async () => {
    setIsLoading(true)
    setError('')

    try {
      const updatedItem = {
        id: item.id,
        name: name,
        description: desc,
      }

      const { data } = await api.put(`/api/categories/${item.id}`, updatedItem)
      callback && callback(updatedItem)
      handleClose && handleClose()
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>    
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update [{item.name}]</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId={`${item.id}-01`}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Category name"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={`${item.id}-02`}>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={5} value={desc}
                onChange={(e) => setDesc(e.currentTarget.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>          
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateProduct}>
            <ShowSpinnerText text='Update' isLoading={isLoading} />
          </Button>
        </Modal.Footer>
        {error && <ToastMessage show={true} error={error} />}
      </Modal>      
    </>
  );
}

export default AdminCategoryUpdate;
