import { createContext, useContext, useState } from 'react'
import api from '../d_api/api'

const authContext = createContext({})

export const AuthenProvider = (props) => {
    const auth = useProviderAuth()
    return (
        <authContext.Provider value={auth}>{props.children}</authContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(authContext)
}

const useProviderAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useState([])
    const [error, setError] = useState('')

    const getToken = () => { return localStorage.getItem('ABC') }
    const setToken = (token) => { localStorage.setItem('ABC', token) }

    const autoSignIn = async () => {
        setError('')
        try {
            const { data } = await api.get('/api/users/me')
            setIsAuthenticated(true)
            setUser(data)
        } catch (error) {
            setIsAuthenticated(false)
        }
    }

    const login = async (key) => {
        setError('')
        try {
            const { data } = await api.post("/api/users/authenticate", {
                key: key
            })

            setUser(data.user)
            setToken(data.accessToken)
            setError('')
            setIsAuthenticated(true)
        } catch (errorMessage) {
            setIsAuthenticated(false)
            setUser({})
            setToken('')
            setError(errorMessage)
        }
    }

    const logout = async () => {
        try {
            const { data } = await api.post('/api/users/logout')
            setError('')
        } catch (error) {
            const message = error.response?.data.message || error.message
            setError(message)
        } finally {
            setToken('')
        }
    }

    return {
        isAuthenticated,
        getToken,
        error,
        user,
        autoSignIn,
        login,
        logout
    }
}




