import React, { Component, useEffect } from 'react';

const WidgetProjects = () => {
  const exYear = new Date().getUTCFullYear() - 2019

  return (
    <>
      <div class="container-xxl project py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Our Projects</h4>
            <h1 class="display-5 mb-4">Visit Our Latest Projects And Our Innovative Works</h1>
          </div>
          <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
            <div class="col-lg-4">
              <div class="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 active" data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button">
                  <h3 class="m-0">01. Modern Complex</h3>
                </button>
                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4" data-bs-toggle="pill" data-bs-target="#tab-pane-2" type="button">
                  <h3 class="m-0">02. Royal Hotel</h3>
                </button>
                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4" data-bs-toggle="pill" data-bs-target="#tab-pane-3" type="button">
                  <h3 class="m-0">03. Mexwel Buiding</h3>
                </button>
                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-0" data-bs-toggle="pill" data-bs-target="#tab-pane-4" type="button">
                  <h3 class="m-0">04. Shopping Complex</h3>
                </button>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="tab-content w-100">
                <div class="tab-pane fade show active" id="tab-pane-1">
                  <div class="row g-4">
                    <div class="col-md-6" style={{ "minHeight": "350px" }}>
                      <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100" src="img/project-1.jpg"
                          style={{ "objectFit": "cover" }} alt="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h1 class="mb-3">{exYear} Years Of Experience In Rattan Furniture</h1>
                      <p class="mb-4">From the beginning we’ve worked alongside many of the most important media and publishing companies. The results achieved and shared experiences are our greatest satisfaction, combined with the trust and lasting relationships built over time.</p>
                      <p><i class="fa fa-check text-primary me-3"></i>High quality</p>
                      <p><i class="fa fa-check text-primary me-3"></i>Special rattan Weaving</p>
                      <p><i class="fa fa-check text-primary me-3"></i>Rattan Furniture</p>
                      <a href="" class="btn btn-primary py-3 px-5 mt-3">Read More</a>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab-pane-2">
                  <div class="row g-4">
                    <div class="col-md-6" style={{ "minHeight": "350px" }}>
                      <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100" src="img/project-2.jpg"
                          style={{ "objectFit": "cover" }} alt="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h1 class="mb-3">{exYear} Years Of Experience In Rattan Furniture</h1>
                      <p class="mb-4">From the beginning we’ve worked alongside many of the most important media and publishing companies. The results achieved and shared experiences are our greatest satisfaction, combined with the trust and lasting relationships built over time.</p>
                      <p><i class="fa fa-check text-primary me-3"></i>High quality</p>
                      <p><i class="fa fa-check text-primary me-3"></i>Special rattan Weaving</p>
                      <p><i class="fa fa-check text-primary me-3"></i>Rattan Furniture</p>
                      <a href="" class="btn btn-primary py-3 px-5 mt-3">Read More</a>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab-pane-3">
                  <div class="row g-4">
                    <div class="col-md-6" style={{ "minHeight": "350px" }}>
                      <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100" src="img/project-3.jpg"
                          style={{ "objectFit": "cover" }} alt="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h1 class="mb-3">{exYear} Years Of Experience In Rattan Furniture</h1>
                      <p class="mb-4">From the beginning we’ve worked alongside many of the most important media and publishing companies. The results achieved and shared experiences are our greatest satisfaction, combined with the trust and lasting relationships built over time.</p>
                      <p><i class="fa fa-check text-primary me-3"></i>High quality</p>
                      <p><i class="fa fa-check text-primary me-3"></i>Special rattan Weaving</p>
                      <p><i class="fa fa-check text-primary me-3"></i>Rattan Furniture</p>
                      <a href="" class="btn btn-primary py-3 px-5 mt-3">Read More</a>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab-pane-4">
                  <div class="row g-4">
                    <div class="col-md-6" style={{ "minHeight": "350px" }}>
                      <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100" src="img/project-4.jpg"
                          style={{ "objectFit": "cover" }} alt="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h1 class="mb-3">{exYear} Years Of Experience In Rattan Furniture</h1>
                      <p class="mb-4">From the beginning we’ve worked alongside many of the most important media and publishing companies. The results achieved and shared experiences are our greatest satisfaction, combined with the trust and lasting relationships built over time.</p>
                      <p><i class="fa fa-check text-primary me-3"></i>High quality</p>
                      <p><i class="fa fa-check text-primary me-3"></i>Special rattan Weaving</p>
                      <p><i class="fa fa-check text-primary me-3"></i>Rattan Furniture</p>
                      <a href="" class="btn btn-primary py-3 px-5 mt-3">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetProjects;