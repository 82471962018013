import React, { useCallback, useEffect, useState } from 'react';
import api from '../../d_api/api';
import { Navigate, useLocation } from 'react-router-dom';
import "./Login.css"
import { useAuth } from '../../f_hooks/useAuth';
import ShowSpinnerText from '../../c_components/SpinnerButton';
import ToastMessage from '../../c_components/ToastMessage';

const Login = () => {
  const auth = useAuth()
  const [key, setKey] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const handleLogin = async () => {
    setIsLoading(true)
    try {
      await auth.login(key)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    try {
      const token = auth.getToken()
      if (token) {
        auth.autoSignIn()
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [])

  const handleOnChange = (e) => {
    setKey(e.target.value)
  }

  return (
    !isLoading && auth.isAuthenticated ? <Navigate to='/admin' /> : <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Login Admin Page</h4>
            <h1 class="display-5 mb-4">We Are Creative Rattan Furniture Team For Your Dream Home</h1>
            <div class="container">
              <div class="row g-3">
                <div class="col">
                  <input type="password" onChange={handleOnChange} value={key} class="form-control" placeholder="Your Secret Key" style={{ "height": "55px", "width": "300px" }} />
                </div>
                <div class="col">
                  <button class="btn btn-primary py-3" disabled={isLoading} onClick={handleLogin} style={{ "height": "55px", "width": "100%" }}><ShowSpinnerText text='Do Login' isLoading={isLoading} /></button>
                </div>
              </div>
              {auth.error && <ToastMessage show={true} error={auth.error} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
