import React, { Component } from 'react';

const ProductItem = ({ item }) => {
    return (
        item && <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="team-item position-relative">
                <div class="position-relative border border-primary" style={{ "height": "276px", "width": "276px !important", "textAlign": "center" }}>
                    <a href={`/products/${item.id}`}><img style={{ "height": "100%" }} class="img-fluid" src={`${item.image}`} alt="" /></a>
                </div>
                <div class="bg-light text-left p-4">
                    <h3 class="product-name mt-2">{item.name}</h3>
                    <span class="product-desc text-primary">{item.description}</span>
                </div>
            </div>
        </div>
    )
}

export default ProductItem;
