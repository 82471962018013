import React, { Component, useEffect } from 'react';

const WidgetTestimonial = () => {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ "maxWidth": "600px" }}>
            <h4 class="section-title">Testimonial</h4>
            <h1 class="display-5 mb-4">Thousands Of Customers Who Trust Us And Our Services</h1>
          </div>
          <div class="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
            <div class="testimonial-item text-center" data-dot="<img class='img-fluid' src='img/testimonial-1.jpg' alt=''/>">
              <p class="fs-5">A brand for a company is like a reputation for a person. You earn a reputation by trying to do hard things well.</p>
              <h3>Henry Ford</h3>
              <span class="text-primary">Profession</span>
            </div>
            <div class="testimonial-item text-center" data-dot="<img class='img-fluid' src='img/testimonial-2.jpg' alt=''/>">
              <p class="fs-5">Customer service shouldn't just be a department, it should be the entire company.</p>
              <h3>Bob Hooey</h3>
              <span class="text-primary">Excellence</span>
            </div>
            <div class="testimonial-item text-center" data-dot="<img class='img-fluid' src='img/testimonial-3.jpg' alt=''/>">
              <p class="fs-5">It's through vulnerability that human beings create connections. The more vulnerable we can be with one another, the more that we'll trust one another and the more we'll be able to collaborate effectively.</p>
              <h3>William J. Brennan, Jr.</h3>
              <span class="text-primary">Fast delivery</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetTestimonial;