import React, { Component } from 'react';
import WidgetTestimonial from '../c_components/WidgetTestimonial';
import WidgetServices1 from '../c_components/WidgetServices1';
import WidgetServices2 from '../c_components/WidgetServices2';
import { Layout } from '../a_layout/Layout';

class Services extends Component {
    static displayName = Services.name;

    render() {
        return (
            <>
                <div class="container-fluid page-header-3 py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
                    <div class="container py-5">
                        <h1 class="display-1 text-white animated slideInDown">Services</h1>
                        <nav aria-label="breadcrumb animated slideInDown">
                            <ol class="breadcrumb text-uppercase mb-0">
                                <li class="breadcrumb-item"><a class="text-white" href="#">Home</a></li>
                                <li class="breadcrumb-item"><a class="text-white" href="#">Pages</a></li>
                                <li class="breadcrumb-item text-primary active" aria-current="page">Services</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <WidgetServices1 />
                <WidgetServices2 />
                <WidgetTestimonial />
            </>
        );
    }
}

export default Layout(Services);
