import React, { Component, useEffect } from 'react';

const WidgetServices1 = () => {
  useEffect(() => {
  })

  const exYear = new Date().getUTCFullYear() - 2019

  return (
    <>
      <div class="container-xxl py-5">
        <div class="container pt-5">
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="fact-item text-center bg-light h-100 p-5 pt-0">
                <div class="fact-icon">
                  <img src="img/icons/icon-2.png" alt="" />
                </div>
                <h3 class="mb-3">High quality</h3>
                <p class="mb-0">A single, thin layer of material. It's strong and durable, making it great value for money.</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="fact-item text-center bg-light h-100 p-5 pt-0">
                <div class="fact-icon">
                  <img src="img/icons/icon-3.png" alt="" />
                </div>
                <h3 class="mb-3">Special rattan Weaving</h3>
                <p class="mb-0">A beautiful, strong effect that is more three-dimensional in shape with a high-end feel to it.</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="fact-item text-center bg-light h-100 p-5 pt-0">
                <div class="fact-icon">
                  <img src="img/icons/icon-4.png" alt="" />
                </div>
                <h3 class="mb-3">Rattan Furniture</h3>
                <p class="mb-0">Rattan furniture can be made from natural or synthetic weaves. When you’re exploring buying outdoor rattan furniture you’ll find it’s synthetic, not natural. And there’s a good reason for this.</p>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </>
  );
}

export default WidgetServices1;