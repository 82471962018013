import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { imageReviewContainerStyle, imageReviewIconRemoveStyle, imageReviewStyle } from '../e_services/Constants';

const DropZonePreviews = (props) => {
  const { onRemoveImage, onAddedNewImage } = props
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxSize: 1 * 1024 * 1024,
    maxFiles: 5,
    onDrop: acceptedFiles => {
      acceptedFiles.map((file, index) => {
        const reader = new FileReader()
        file.preview = URL.createObjectURL(file)
        reader.onload = () => {
            file.data = reader.result
        }
        reader.onloadend = () => {
          onAddedNewImage && onAddedNewImage({ index: index, data: file.data })
        }
        reader.readAsDataURL(file)
      })

      setFiles(acceptedFiles);
    }
  });

  const handleRemove = (index) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
    onRemoveImage && onRemoveImage({ index: index })
  }

  const thumbs = files.map((file, index) => (
    <div style={imageReviewContainerStyle}>
      <img
        src={file.preview}
        style={imageReviewStyle}
        // Revoke data uri after image is loaded
        onLoad={() => { URL.revokeObjectURL(file.preview) }}
      />
      <i onClick={() => { handleRemove(index) }} style={imageReviewIconRemoveStyle} class="fa fa-times" aria-hidden="true"></i>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => {
      URL.revokeObjectURL(file.preview)
    });
  }, []);

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {thumbs}
    </section>
  );
}

export default DropZonePreviews;