import React, { useEffect, useState } from 'react';
import NavMenu from './NavMenu';
import ToolbarStart from './ToolbarStart';
import Footer from './Footer';

export const Layout = (WrappedComponent) => {
  const MyLayout = () => {
    return (
        <>
          <ToolbarStart />
          <NavMenu />
          <WrappedComponent />
          <Footer />
        </>)
  }

  return MyLayout
}
