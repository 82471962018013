import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import ShowSpinnerText from '../../c_components/SpinnerButton';
import ToastMessage from '../../c_components/ToastMessage';
import api from '../../d_api/api';
import ImagePreview from '../../c_components/ImagePreview';
import DropZonePreviews from '../../c_components/DropZonePreviews';
import { productDefaultProperties, productProperties } from '../../e_services/Constants';

const AdminProductCreate = (props) => {
  const { show, handleClose, categories, callback } = props
  const [categoryId, setCategoryId] = useState(null)
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const [images, setImages] = useState([])
  const [newImages, setNewImages] = useState([])

  const [shouldRender, setShouldRender] = useState(false)
  const [properties, setProperties] = useState(productDefaultProperties)

  const handleCreateNew = async () => {
    setError('')
    setIsLoading(true)

    const catId = categoryId || (categories[0].id ?? '')

    try {
      const item = {
        name: name,
        categoryId: catId,
        description: desc,
        images: images,
        newImages: newImages,
        properties: properties
      }

      const { data } = await api.post(`/api/products`, item)
      callback && callback(data)
      handleClose()
    } catch (error) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onRemoveImages = (index) => {
    const newImages = [...images]
    newImages.splice(index, 1)
    setImages(newImages)
  }

  const onAddedNewImage = (item) => {
    const index = newImages.findIndex(x => item.index === x.index)
    if (index >= 0) 
    {
      newImages[index] = item  
    } else {
      newImages.push(item)
    }
  }

  const onRemoveNewImage = (index) => {
    newImages.splice(index, 1)
  }

  const handleUpdateProperties = (v, e) => {
    properties[v] = e.currentTarget.value
    setShouldRender(!shouldRender)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Create new [{name}]</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId={`eed5af4f609dae2345c6c3c1d9bfe1db-00`}>
              <Form.Label>Category</Form.Label>
              <Form.Select aria-label="" value={categoryId} onChange={(e) => { setCategoryId(e.currentTarget.value) }}>
                {categories.map((v, k) => {
                  return <option value={v.id}>{v.name}</option>
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId={`eed5af4f609dae2345c6c3c1d9bfe1db-01`}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Product name"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={`eed5af4f609dae2345c6c3c1d9bfe1db-02`}>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={5} value={desc}
                onChange={(e) => setDesc(e.currentTarget.value)}
              />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId={`eed5af4f609dae2345c6c3c1d9bfe1db-03`}>
              <Form.Label>Images</Form.Label>
              <p>
                <ImagePreview callback={onRemoveImages} images={images} />
              </p>
            </Form.Group> */}
            <Form.Group className="mb-3" controlId={`eed5af4f609dae2345c6c3c1d9bfe1db-04`}>
              <Form.Label>Adding new images</Form.Label>
              <DropZonePreviews onRemoveNewImage={onRemoveNewImage} onAddedNewImage={onAddedNewImage} />
            </Form.Group>
            <Form.Group className="mb-3" controlId={`eed5af4f609dae2345c6c3c1d9bfe1db-05`}>
              <div class="container">
                <div class="row row-cols-4">
                  {productProperties.map((k, v) => {
                    const oddcolor = [0, 1, 4, 5].includes(v) ? 'product-specs--oddcolor' : ''
                    return <>
                      <div class={`col ${oddcolor} product-specs--name`}>{k}:</div>
                      <div class={`col ${oddcolor} product-specs--value`}><input style={{'width': '100%'}} onChange={(e) => handleUpdateProperties(v, e)} type='text' value={properties[v]} /></div>
                    </>
                  })}
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateNew}>
            <ShowSpinnerText text='Create New' isLoading={isLoading} />
          </Button>
        </Modal.Footer>
      </Modal>
      {error && <ToastMessage show={true} error={error} />}
    </>
  );
}

export default AdminProductCreate;
